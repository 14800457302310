footer {
display: flex;
justify-content: center;
align-items: center;
background-color: #ffffff;
color: #4B5168;
font-size: 12px;
line-height: 15px;
height: 48px;
width: 100%;
}