.navbar {
    width: 100%;
    height: 56px;
    background: #FFFFFF;
    border-bottom: 1px solid #C6C8CE;
    margin: 0px;
    padding: 5px;
    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-width: 100%;
        .navbar-brand {
            img {
                margin: 0px 8px;
                height: 20px;
            }
        }
    }
    svg {
        color: #1F2747;
        width: 16px;
        height: 18px;
    }
    .nav-leftcontent-wrapper {
        // width: 208px;
        // align-items: center;
        display: flex;
        align-items: center;
        .nav-icon-container {
            display: flex;
            a {
                padding: 11px 15px;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                width: 40px;
                border-radius: 8px;
            }
                :hover {
                    background-color: #F3F4F5;
                }
        }
        .user-account {
            margin-left: 8px;
            .dropdown-toggle::after {
                content: none;
            }
            .user-account-icon {
                width: 40px;
                height: 40px;
                cursor: pointer;
            }
            .dropdown-menu {
                width: 232px;
                min-height: 104px;
                background: #FFFFFF;
                border: 1px solid #C6C8CE;
                box-sizing: border-box;
                box-shadow: 0px 8px 10px rgb(0 0 0 / 14%);
                border-radius: 8px;
                padding: 8px;
                a {
                    color: #1F2747;
                    font-size: 14px;
                    text-decoration: none;
                    line-height: 17px;
                }
                .dropdown-item {
                    padding: 9px 16px;
                    &:hover {
                        background: #F3F4F5;
                        border-radius: 8px;
                    }
                }
            }
        }
    }
  
}
.navbarTitle {
    display: flex;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    cursor: default;
    color: #1f2747;

    svg:first-child {
        margin-right: 10px;
    }
    svg {
        color: #1F2747;
        width: 16px;
        height: 18px;
    }
    img {
        height: 30px;
        margin-right: 5px;
    }
}